import React, { useState } from "react";

import { VerticalSpacing } from "@wayhome-uk/design-system";
import { Redirect } from "react-router-dom";

import { AuthMessage } from "components/auth/auth-message";
import { BackgroundOverlay } from "components/background-overlay";
import { Modal } from "components/modal";
import { getRoutePathWithParamsAndQueryStrings, routePaths } from "config/route-paths";
import { routes } from "config/routes";

import { LoginForm } from "../login-form";

export interface Props {
    className?: string;
    onClose?: () => void;
    onSuccessfulLogin?: () => void;
    onSuccessfulLoginWithToken?: (token: string, uuid: string) => void;
    redirectOnLogin?: boolean;
    email?: string;
    password?: string;
}

export const LoginModal = ({
    className,
    onClose,
    onSuccessfulLoginWithToken,
    onSuccessfulLogin,
    redirectOnLogin = true,
    email = "",
    password = "",
}: Props) => {
    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
    const [uuid, setUuid] = useState<string | null>(null);

    const handleSuccessfulLogin = (uuid: string, token: string) => {
        setUuid(uuid);

        if (onSuccessfulLogin) {
            onSuccessfulLogin();
        }

        if (onSuccessfulLoginWithToken) {
            onSuccessfulLoginWithToken(token, uuid);
        }

        setShouldRedirect(redirectOnLogin);

        if (onClose) {
            onClose();
        }
    };

    return shouldRedirect && uuid ? (
        <Redirect to={getRoutePathWithParamsAndQueryStrings(routes.product.suitabilityMatches.path, { uuid })} />
    ) : (
        <BackgroundOverlay className={className} data-testid="login-modal">
            <Modal
                title="Sign in to Wayhome"
                footerText="Don't have an account yet?"
                footerLinkText="Get started"
                footerLinkHref={routePaths.marketing.signup.path}
                onClose={onClose}
                canCloseModalOnFooterLinkClick
            >
                <AuthMessage />
                <VerticalSpacing size={24} />
                <LoginForm onSuccessfulLogin={handleSuccessfulLogin} email={email} password={password} />
            </Modal>
        </BackgroundOverlay>
    );
};
