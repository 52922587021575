import React from "react";

import { SecondaryButton, SecondaryLink } from "components/buttons-and-links";
import { routePaths } from "config/route-paths";

export const CtaButton = () => {
    return (
        <SecondaryButton>
            <SecondaryLink data-test-id="cta-next-step" as="a" href={routePaths.marketing.signup.path}>
                Sign Up
            </SecondaryLink>
        </SecondaryButton>
    );
};
