import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PageWidth } from "@wayhome-uk/design-system";
import { Link } from "react-router-dom";
import styled from "styled-components";

import IconWLogo from "components/media/svg/icon-w-logo.svg";
import IconWayhomeLogo from "components/media/svg/icon-wayhome-logo.svg";

import { filteredMenuByLoggedIn, getMainMenu, getMenuItem, getMenuType, getSubMenu } from "./helpers";
import { HeaderLink } from "./types";

export interface INavbarLoginDesktopProps {
    className?: string;
    isLoggedIn: boolean;
    menu: HeaderLink[];
}

export const NavbarLoginDesktop = ({ className, isLoggedIn, menu }: INavbarLoginDesktopProps) => {
    const theme = useTheme();
    const isMaxMediumSize = useMediaQuery(theme.breakpoints.down("lg"));
    const size = isMaxMediumSize ? "medium" : "large";

    const filteredMobileMenuOptions = menu
        .filter((option: HeaderLink) => filteredMenuByLoggedIn(option, isLoggedIn))
        .map((option: HeaderLink) => getSubMenu(option, menu, size, "more", "isInMoreMenu"))
        .map((option: HeaderLink) => getSubMenu(option, menu, size, "my-account", "isInAccountsMenu"))
        .filter((option: HeaderLink) => getMainMenu(option, size));

    const infoMenu = getMenuType(filteredMobileMenuOptions, "info");
    const homesMenu = getMenuType(filteredMobileMenuOptions, "homes");
    const sigInMenu = getMenuType(filteredMobileMenuOptions, "sign-in");

    return (
        <Wrapper data-test-id="navbar-login-desktop" className={className}>
            <PageWidth>
                <Container>
                    <StyledLink data-test-id="navbar-login-desktop-logo" to={"/"} title={"Wayhome"}>
                        <picture>
                            <source srcSet={IconWayhomeLogo} media="(min-width: 1440px)" />
                            <img src={IconWLogo} alt="wayhome-logo" />
                        </picture>
                    </StyledLink>

                    <Menu data-test-id="navbar-login-desktop-menu">
                        {infoMenu.map((option: HeaderLink) => getMenuItem(option))}

                        {homesMenu.length > 0 && <Border />}
                        {homesMenu.map((option: HeaderLink) => getMenuItem(option))}

                        {sigInMenu.length > 0 && <Border />}
                        {sigInMenu.map((option: HeaderLink, index: number) =>
                            getMenuItem(option, index === sigInMenu.length - 1),
                        )}
                    </Menu>
                </Container>
            </PageWidth>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: none;

    @media (min-width: ${({ theme }) => theme.largeMinWidth}) {
        display: block;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledLink = styled(Link)`
    padding: ${({ theme }) => theme.spacing24} 0;
`;

const Menu = styled.ul`
    display: flex;
    align-items: center;
`;

const Border = styled.span`
    display: flex;
    align-content: center;
    justify-items: center;
    height: 24px;
    border-right: 1px solid ${({ theme }) => theme.neutral100};
    margin-left: ${({ theme }) => theme.spacing24};
`;
