import { useCallback, useEffect, useState } from "react";

import { TCookieFirstContext } from "../type";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop: () => void = () => {};
export const initialContext = () => {
    const consentData = localStorage.getItem("cookiefirst-consent");
    const consent = consentData ? JSON.parse(consentData) : null;

    return {
        consent,
        openPanel: noop,
        closePanel: noop,
        updateConsent: async () => noop(),
        acceptCategory: async () => noop(),
        acceptAllCategories: async () => noop(),
        acceptPreselectedCategories: async () => noop(),
        declineAllCategories: async () => noop(),
        declineCategory: async () => noop(),
        withdrawConsent: async () => noop(),
    };
};

const getContextFromCfObject = (CookieFirst: TCookieFirstContext): TCookieFirstContext => ({
    consent: CookieFirst.consent,
    openPanel: CookieFirst.openPanel,
    closePanel: CookieFirst.closePanel,
    updateConsent: CookieFirst.updateConsent,
    acceptCategory: CookieFirst.acceptCategory,
    acceptAllCategories: CookieFirst.acceptAllCategories,
    acceptPreselectedCategories: CookieFirst.acceptPreselectedCategories,
    declineAllCategories: CookieFirst.declineAllCategories,
    declineCategory: CookieFirst.declineCategory,
    withdrawConsent: CookieFirst.withdrawConsent,
});

const INIT_EVENT = "cf_init";
const CONSENT_EVENT = "cf_consent";

declare global {
    interface Window {
        CookieFirst: TCookieFirstContext;
    }
}

export const useConsent = (): TCookieFirstContext => {
    const [context, setContext] = useState<TCookieFirstContext>(() => initialContext());

    const handleInit = useCallback(() => {
        if (!window.CookieFirst) {
            return;
        }

        setContext(getContextFromCfObject(window.CookieFirst));
    }, []);

    const handleConsent = useCallback(() => {
        if (!window.CookieFirst) {
            return;
        }

        setContext(getContextFromCfObject(window.CookieFirst));
    }, []);

    useEffect(() => {
        window.addEventListener(INIT_EVENT, handleInit);
        window.addEventListener(CONSENT_EVENT, handleConsent);

        return () => {
            window.removeEventListener(INIT_EVENT, handleInit);
            window.removeEventListener(CONSENT_EVENT, handleConsent);
        };
    }, [handleConsent, handleInit]);

    return context;
};
