import React, { Suspense, lazy } from "react";

import { LoadingIndicator } from "@wayhome-uk/design-system";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { CustomerRoute } from "components/customer-route";
import { ProtectedRoute } from "components/protected-route";
import { routePaths } from "config/route-paths";
import { withSignUpRedirect } from "hoc/with-signup-redirect";
import Home from "pages/home";
import { retry } from "utils/retry";

const About = lazy(() => retry(() => import("pages/about")));
const AccountSettings = lazy(() => retry(() => import("pages/account-settings")));
const AgreementInPrinciple = lazy(() => retry(() => import("pages/agreement-in-principle")));
const ApplicationProcess = lazy(() => retry(() => import("pages/application-process")));
const CookiePolicy = lazy(() => retry(() => import("pages/cookie-policy")));
const Explainer = lazy(() => retry(() => import("pages/explainer")));
const JoinTheWaitList = lazy(() => retry(() => import("pages/join-the-wait-list")));
const Login = lazy(() => retry(() => import("pages/login")));
const LoginToSeeHomes = lazy(() => retry(() => import("pages/login-to-see-homes")));
const MatchedAutomatedPSF = lazy(() => retry(() => import("pages/matched-automated-PSF")));
const OfferConfirmation = lazy(() => retry(() => import("pages/offer-confirmation")));
const PageNotFound = lazy(() => retry(() => import("pages/page-not-found")));
const PasswordReset = lazy(() => retry(() => import("pages/password-reset")));
const Press = lazy(() => retry(() => import("pages/press")));
const PropertyDetail = lazy(() => retry(() => import("pages/property-detail")));
const RequestViewingSuccess = lazy(() => retry(() => import("pages/request-viewing-success")));
const SuitabilityMatches = lazy(() => retry(() => import("pages/suitability-matches")));
const WayhomeOffer = lazy(() => retry(() => import("pages/wayhome-offer")));
const WhereWeBuy = lazy(() => retry(() => import("pages/where-we-buy")));

export const Routes = withSignUpRedirect(() => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Switch>
                <Route exact={true} path={routePaths.marketing.joinTheWaitList.path} component={JoinTheWaitList} />
                <Route exact={true} path={routePaths.marketing.home.path} component={Home} />
                <Route exact={true} path={routePaths.marketing.about.path} component={About} />
                <Route exact={true} path={routePaths.marketing.login.path} component={Login} />
                <Route
                    exact={true}
                    path={routePaths.marketing.applicationProcess.path}
                    component={ApplicationProcess}
                />
                <Route exact={true} path={routePaths.misc.press.path} component={Press} />
                <Route exact={true} path={routePaths.marketing.whereWeBuy.path} component={WhereWeBuy} />
                <Route exact={true} path={routePaths.misc.cookiePolicy.path} component={CookiePolicy} />
                <Route exact={true} path={routePaths.product.homesNotFound.path} component={LoginToSeeHomes} />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.suitabilityMatches.path}
                    component={SuitabilityMatches}
                />
                <CustomerRoute exact={true} path={routePaths.product.propertyDetail.path} component={PropertyDetail} />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.offerConfirmation.path}
                    component={OfferConfirmation}
                />
                <CustomerRoute
                    exact={true}
                    path={routePaths.product.offPlatformOfferConfirmation.path}
                    component={OfferConfirmation}
                />
                <Route exact={true} path={routePaths.misc.explainer.path} component={Explainer} />
                <Route exact={false} path={routePaths.product.passwordReset.path} component={PasswordReset} />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.accountSettings.path}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                    component={AccountSettings}
                />
                <ProtectedRoute
                    exact={true}
                    path={routePaths.product.estimateCosts.path}
                    redirectRoute={`${routePaths.marketing.applicationProcess.path}?sign-in=true`}
                    component={MatchedAutomatedPSF}
                />
                <Route
                    exact={true}
                    path={routePaths.product.requestViewingSuccess.path}
                    component={RequestViewingSuccess}
                />
                <ProtectedRoute
                    exact={false}
                    path={routePaths.product.wayhomeOffer.path}
                    component={WayhomeOffer}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                />
                <ProtectedRoute
                    exact={false}
                    path={routePaths.product.agreementInPrinciple.path}
                    component={AgreementInPrinciple}
                    redirectRoute={`${routePaths.marketing.home.path}?sign-in=true`}
                />
                <Route component={PageNotFound} />
            </Switch>
        </Suspense>
    );
});

const PageLoader = styled(LoadingIndicator)`
    padding: 150px 0;
`;
