import React, { useRef } from "react";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { GlobalStyles, MuiTheme } from "@wayhome-uk/design-system";
import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Provider } from "react-redux";
import { Store } from "redux";
import { AppState } from "store";
import { ThemeProvider } from "styled-components/macro";

import { BranchPageLayout } from "components/branch-page-layout";
import { ErrorBoundaryLayout } from "components/error-boundary-layout";
import { MaintenanceMode } from "components/maintenance-mode";
import { MetaTags } from "components/meta-tags";
import { Theme } from "config/theme";
import { withIntercom } from "hoc/with-intercom";
import { AuthProvider } from "hooks/use-auth";
import { CookieFirstProvider } from "hooks/use-cookie-first";
import { openIntercomLiveChat } from "utils/helpers";

import { Routes } from "./routes";
import ScrollRestoration from "./scroll-restoration";
import { GlobalStyleComp } from "./styles/global";

interface Props {
    store: Store<AppState>;
    history: History;
}

const App = ({ store, history }: Props) => {
    const visitedUrl = useRef(new Map());

    const { isMaintenanceMode } = useFlags();

    const showIntercom = new URLSearchParams(window.location.search).has("show_intercom");

    if (isMaintenanceMode) {
        return <MaintenanceMode />;
    }

    if (showIntercom) {
        openIntercomLiveChat();
    }

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <MetaTags
                    title="Wayhome | Gradual homeownership"
                    description="Wayhome helps you buy a home when a mortgage doesn’t work. Part buy, part rent a home you actually love with a 5% deposit. Then gradually buy us out over time."
                />

                <ScrollRestoration visitedUrl={visitedUrl.current} />
                <ThemeProvider theme={Theme}>
                    <MuiThemeProvider theme={MuiTheme}>
                        <AuthProvider>
                            <CookieFirstProvider>
                                <>
                                    <GlobalStyles />
                                    <GlobalStyleComp />
                                    <ErrorBoundaryLayout>
                                        <BranchPageLayout>
                                            <Routes />
                                        </BranchPageLayout>
                                    </ErrorBoundaryLayout>
                                </>
                            </CookieFirstProvider>
                        </AuthProvider>
                    </MuiThemeProvider>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    );
};

export default withIntercom(App);
