import React from "react";

import { Button } from "@wayhome-uk/design-system";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { routes } from "config/routes";

import { Dropdown } from "./dropdown";
import { HeaderLink, TAppearanceSize, TAppearanceSizeKey } from "./types";

export const getMenuType = (menu: HeaderLink[], type: string) =>
    menu.filter((option: HeaderLink) => option.type === type);

export const getMainMenu = (option: HeaderLink, size: string) =>
    size === "medium" ? option.appearance.medium.isInMainMenu : option.appearance.large.isInMainMenu;

export const filteredMenuByLoggedIn = (option: HeaderLink, isLoggedIn: boolean) =>
    isLoggedIn ? option.showWhenLoggedIn : option.showWhenLoggedOut;

export const filterByMainMenu = (option: HeaderLink, size: TAppearanceSize) => option.appearance[size].isInMainMenu;

export const getSubMenu = (
    option: HeaderLink,
    menu: HeaderLink[],
    size: string,
    id: string,
    sizeKey: TAppearanceSizeKey,
) => {
    if (option.id === id) {
        option.subMenu = menu.filter((option) => {
            switch (size) {
                case "small":
                    return option.appearance.small[sizeKey];
                case "medium":
                    return option.appearance.medium[sizeKey];
                case "large":
                    return option.appearance.large[sizeKey];
                default:
                    return null;
            }
        });
    }
    return option;
};

const getSignUpLink = (option: HeaderLink) => {
    const isNewSignUp = option.url === routes.marketing.signup.path;

    return isNewSignUp ? (
        <StyledNavLink title={option.title} data-test-id={`navbar-login-button-${option.id}`} as="a" href={option.url}>
            <ButtonText>{option.title}</ButtonText>
        </StyledNavLink>
    ) : (
        <StyledNavLink title={option.title} data-test-id={`navbar-login-button-${option.id}`} to={option.url} exact>
            <ButtonText>{option.title}</ButtonText>
        </StyledNavLink>
    );
};

export const getMenuItem = (option: HeaderLink, isLast?: boolean) => {
    if (option.hasSubMenu) {
        return (
            <MenuItem key={`menu-item-${option.id}`} data-test-id={`menu-item-${option.id}`}>
                <Dropdown menu={option.subMenu || []} title={option.title} isLast={isLast} />
            </MenuItem>
        );
    }
    if (option.isButton) {
        const linkComponent = getSignUpLink(option);

        return (
            <MenuItem key={`menu-item-${option.id}`} data-test-id={`menu-item-${option.id}`}>
                {option.url ? (
                    linkComponent
                ) : (
                    <StyledButton onClick={option.handleClick} data-test-id={`navbar-login-button-${option.id}`}>
                        <ButtonText>{option.title}</ButtonText>
                    </StyledButton>
                )}
            </MenuItem>
        );
    }

    if (option.isExternal) {
        return (
            <ExternalLink
                key={`menu-item-${option.id}`}
                href={option.url}
                title={option.title}
                data-test-id={option.testId}
            >
                {option.title}
            </ExternalLink>
        );
    }

    return (
        <MenuItem key={`menu-item-${option.id}`} data-test-id={`menu-item-${option.id}`}>
            {option.handleClick ? (
                <MenuItemNoLink data-test-id={option.testId} onClick={option.handleClick}>
                    {option.title}
                </MenuItemNoLink>
            ) : (
                <MenuLink to={option.url} title={option.title} data-test-id={option.testId} exact={true}>
                    {option.title}
                </MenuLink>
            )}
        </MenuItem>
    );
};

const ParagraphTextCss = css`
    font-size: ${({ theme }) => theme.type16};
    line-height: 1.6;

    @media (min-width: ${({ theme }) => theme.mediumMinWidth}) {
        font-size: 18px;
    }
`;

const MenuItem = styled.li`
    margin-left: ${({ theme }) => theme.spacing24};
`;

const MenuLink = styled(NavLink)`
    ${ParagraphTextCss};
    display: flex;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.primary500};
    font-weight: 600;
    :hover,
    :focus {
        color: ${({ theme }) => theme.primary800};
        text-decoration: underline;
    }
`;

const StyledNavLink = styled(NavLink)`
    display: block;
    text-align: center;
    line-height: 1.6;
    font-weight: 700;
    padding: ${({ theme }) => `${theme.spacing8} ${theme.spacing16}`};
    background: ${({ theme }) => theme.primary500};
    color: ${({ theme }) => theme.neutral0};
    border-radius: ${({ theme }) => theme.spacing4};
`;

const StyledButton = styled(Button)`
    ${ParagraphTextCss};
    padding: ${({ theme }) => theme.spacing8} ${({ theme }) => theme.spacing16};
`;

const ButtonText = styled.span`
    ${ParagraphTextCss};
`;

const MenuItemNoLink = styled.div`
    ${ParagraphTextCss};
    display: flex;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.primary500};
    font-weight: 600;
    cursor: pointer;
    :hover,
    :focus {
        color: ${({ theme }) => theme.primary800};
        text-decoration: underline;
    }
`;

const ExternalLink = styled.a`
    ${ParagraphTextCss};
    display: flex;
    flex-wrap: wrap;
    color: ${({ theme }) => theme.primary500};
    font-weight: 600;
    cursor: pointer;
    margin-left: ${({ theme }) => theme.spacing24};

    :hover,
    :focus {
        color: ${({ theme }) => theme.primary800};
        text-decoration: underline;
    }
`;
